import React from 'react';
import { Layout, Seo } from 'components';
import { Row, Col } from "react-grid-system";
import HcpIsi from "components/layout/layout-blocks/isi/isi-contents/HcpIsi";

// import page style
import './important-safety-information.scss';
import SideNav from '../codes/components/side-nav';
const ImportantInformationSystem = () => {
  return (
    <Layout indication="patient" className="page-patient-isi"
    noShowStickyIsi>
      <Seo pageTitle="Safety Information"
      pageDescription="Find the Butrans Important Safety Information. See Full Prescribing Info, Med Guide & Boxed Warning." />
      <div className="body-content-container">
      <SideNav pageId="pat-isi" />
        <div className="body-content gutter-all">
          <Row>
            <Col xs={12}>
              <h1>Important Safety Information</h1>
              <div className="isi">
                <div className="isi-content-wrapper">
                  <div className="isi-content">
                    <HcpIsi />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default ImportantInformationSystem;
